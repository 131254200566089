<template lang="pug">
	section#options
		.subOption
			p.subTitle Buscar por Representantes
			.map(ref='map')
				.backdrop(v-if="searching")
				Spinner(v-if="searching").spinner
			.filter
				.uf
					span Estado
					select(v-model="ufId" required)
						option(disabled selected :value="null") UF
						option(v-for="state in ufs" :value="state") {{state}}
				.city
					span Cidade
					select(v-model="cityId" required)
						option(disabled selected :value="null") Cidade
						option(v-for="city in filteredCities" :value="city") {{city}}
				.channel
					span Canal
					select(v-model="channelId" required)
						option(disabled selected :value="null") Canal
						option(v-for="channel in channels" :value="channel") {{channel}}
				.separator
				.results
					span <strong>{{filteredRepresentantes.length}} resultados</strong> encontrados
			ul(v-if="map && filteredRepresentantes.length > 0").units
				li(v-for="cliente in filteredRepresentantes")
					p(v-if="cliente.name").name {{cliente.name}}
					p(v-if="cliente.telephone").text <strong>Contato: </strong> {{cliente.telephone}}
						a(:href="`https://wa.me/${cliente.telephone.replaceAll(/[() -]/g, '')}`")
							span(v-if="cliente.isWhats")
								img(src="@images/whatsapp.png").isWhats
					p(v-if="cliente.address").text <strong>Endereço: </strong> {{cliente.address}}, {{cliente.number}} {{cliente.complement}}
					p(v-if="cliente.city").text <strong>Cidade: </strong> {{cliente.city}}
					p(v-if="cliente.uf").text <strong>Estado: </strong> {{cliente.uf}}
					p(v-if="cliente.cep").text <strong>CEP: </strong> {{cliente.cep}}
					p(v-if="cliente.channel").text <strong>Canal: </strong> {{cliente.channel}}
					hr
			.emptyList(v-else) 
				p
					|Obrigado pelo seu interesse em nossos produtos. 
					br
					br
					|Caso a região e/ou canal não estejam listados, por gentileza, entre em contato com a nossa equipe comercial, pelo telefone: 
					br
					br
				span (44) 3599-8000
</template>
<script>
import Spinner from '@components/Spinner/Spinner'
export default {
	name: "section-options",
	data() {
		return {
			map: null,
			ufs: [],
			ufId: null,
			cities: [],
			citiesIBGE: [],
			cityId: null,
			channelId: null,
			channels: [],
			representantes: [],
			search: '',
			options: {
				clientes: [],
			},
			searching: false,
			apiMapbox: '',
		}
	},
	components: {
		Spinner
	},
	methods: {
		getRepresentantes() {
			this.searching = true
			this.removeMarkers()
			this.$axios.get(`representatives/${this.ufId}`)
			.then(response => {
				response.data.representatives.forEach((representante) => {
					this.representantes.push({
						id: representante.id,
						name: representante.name,
						uf: representante.uf,
						city: representante.city,
						cities: representante.cities.map((city) => this.retira_acentos(city.name.toLowerCase())),
						address: representante.address,
						number: representante.number,
						complement: representante.complement,
						cep: representante.cep,
						telephone: representante.telephone,
						isWhats: representante.isWhats,
						channelId: representante.channelId,
						channel: representante.channel,
					})
					representante.cities.forEach((city) => {
						const addedCity = this.cities.find((addedCity) => +addedCity.id === +city.id)
						if (!addedCity && city.latitude.length && city.longitude.length && city.uf === this.ufId)
							this.cities.push({
								id: city.id,
								uf: city.uf,
								name: city.name,
								geoLocalization: {
									lat: city.latitude,
									long: city.longitude,
								}
							})
					})
					const channel = representante.channel.split(' ')[0];
					if (!this.channels.includes(channel)) {
						this.channels.push(channel);
					}
				})
				this.addMarkers()
				let lat = this.cities.reduce((acc, curr) => acc + +curr.geoLocalization.lat, 0) / this.cities.length;
				let long = this.cities.reduce((acc, curr) => acc + +curr.geoLocalization.long, 0) / this.cities.length;
				this.map.setCenter([long, lat]);
				this.map.setZoom(6)
				this.searching = false
			})
		},
		getUfs() {
			this.$axios.get('ibge-api')
			.then(response => {
				this.ufs = response.data
			})
		},
		getCities() {
			this.$axios.get(`ibge-api/${this.ufId}`)
			.then(response => {
				this.citiesIBGE = response.data
			})
		},
		removeMarkers() {
			// createMap()  // CONFIRMAR
			this.cities.forEach(city => {
				city.marker.remove()
			})
			this.representantes = [];
			this.cities = [];
		},
		addMarkers() {
			this.cities.forEach(city => {
				this.$set(city, 'marker', new mapboxgl.Marker({ color: '#5d9e2f', anchor: 'center' })
					.setLngLat([city.geoLocalization.long, city.geoLocalization.lat])
					.addTo(this.map))
			})
		},
		createMap() {
			for (let setting of this.settings) {
                if (setting.attribute === 'apiMapbox') this.apiMapbox = setting.value;
            }
			mapboxgl.accessToken = `${this.apiMapbox}`
			this.map = new mapboxgl.Map({
				container: this.$refs.map,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: [-52.461213, -24.0427725],
				zoom: 7
			})
		},
		retira_acentos(str)  // https://pt.stackoverflow.com/questions/237762/remover-acentos-javascript
		{
			const com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
			const sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
			let novastr="";
			for(let i=0; i<str.length; i++) {
				let troca=false;
				for (let a=0; a<com_acento.length; a++) {
					if (str.substr(i,1)==com_acento.substr(a,1)) {
						novastr+=sem_acento.substr(a,1);
						troca=true;
						break;
					}
				}
				if (troca==false) {
					novastr+=str.substr(i,1);
				}
			}
			return novastr;
		}
	},
	computed: {
		filteredCities() {
			if (!this.cities.length) return this.citiesIBGE;
			const aqui = this.citiesIBGE
				.filter((cityIBGE) => this.cities
					.map((city) => this.retira_acentos(city.name.toLowerCase()))
					.includes(this.retira_acentos(cityIBGE.toLowerCase())))
			return aqui
		},
		filteredRepresentantes() {
			if (!this.cityId && !this.channelId) return this.representantes
			return this.representantes.filter((representante) => {
				return (this.cityId && representante.cities.includes(this.retira_acentos(this.cityId.toLowerCase()))) || 
					(this.channelId && representante.channel.includes(this.channelId.split(' ')[0]))
			})
		},
		filteredList() {
			const lista = this.city.map(option => {
				if (option.marker.addTo !== undefined){
					option.marker.addTo(this.map)
				}
				return option
			})
			return lista
		},
		settings() {
			return this.$store.state.settings
		}
	},
	created() {
		this.$store.dispatch('fetchSettings')
		this.getUfs()
	},
	watch: {
		ufId: function () {
			this.getCities()
			this.getRepresentantes()
		},
		settings: function () {
            this.createMap() 
        }
	}
}
</script>

<style lang="stylus" scoped src="./Representantes.styl"></style>
